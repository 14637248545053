/* imports ----------------------------------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Philosopher:ital,wght@0,400;1,700&display=swap");

@import url("./css/admin.css");
@import url("./css/layout.css");
@import url("./css/root.css");

/* header -------------------------------------------------------------------------------- */

.header_box {
  width: 100%;
  background-color: #505d64;
  background-image: url(../public/assets/header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.header,
.header_out {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  height: 6rem;
  color: var(--light);
}

.header_out {
  margin: 0 auto;
  max-width: 1300px;
}

.header > .navigation > .navigation__list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.navigation__list li {
  margin: 0 20px;
}

.logo img {
  max-width: 300px;
  max-height: 80px;
}

.icons {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 1.5rem;
  color: var(--light);
}

.icons a {
  height: 24px;
}

.cart-icon {
  position: relative;
}

.cart-icon .items-number {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--accent);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 0.9rem;
  text-align: center;
  line-height: 20px;
  transform: translate(10px, -7px);
  transition: all 0.3s ease;
}

.cart-icon:hover .items-number {
  background-color: var(--light);
  transition: all 0.3s ease;
}

.cart__box {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #1a6177c4;
  width: 450px;
  border-radius: 15px;
  padding: 1rem 1rem;
  backdrop-filter: blur(6px);
  z-index: 100;
}

/* language switcher ----------------------------------------- */

.language__switcher {
  display: flex;
  height: 25px;
  margin-left: 1rem;
}

.language__inner {
  position: absolute;
  display: flex;
  height: 0;
  overflow: hidden;
  flex-direction: column;
  transition: all 0.2s ease;
}

.language__switcher:hover > .language__inner {
  position: absolute;
  flex-direction: column;
  height: 300px;
  transition: all 0.5s ease;
}

.language__icon {
  max-width: 25px;
  margin-bottom: 5px;
}

.language__icon:hover {
  cursor: pointer;
}

/* auth info ------------------------------------------------------------------------- */

.authInfo__box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: #ffffff;
  width: 100%;
  height: 60px;
}

.authInfo__box h3 {
  margin: 0;
  font-size: 1rem;
  color: orange;
  font-weight: 500;
}

.authInfo__box button {
  max-width: 250px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
}

/* products views ----------------------------------------------------------------- */

.thumbnail {
  width: 100%;
  height: 450px;
  margin: 0 auto;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.toggle__controls {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin: 1.5rem 0;
}

.btn__toggle {
  padding: 0.5rem 0.7rem;
}

.btn__toggle:hover {
  color: var(--secondary);
}

.product__price {
  color: var(--secondary);
}

/* list view ---------------------------------------------------------------- */

.list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 4rem;
}

.list__thumbnail {
  min-height: 300px;
  min-width: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.list__item .product__btn button {
  width: 100%;
  margin-bottom: 20px;
}

.list__item .product__btn {
  width: 20%;
}

.list__item__info {
  max-width: 700px;
  width: 40%;
}

.list__product__price {
  text-align: right;
  color: var(--secondary);
  margin-bottom: 0;
}

/* grid view ---------------------------------------------------------------------------------- */

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 3rem;
}

.grid__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  width: 400px;
  margin-bottom: 2rem;
}

.card__product__name {
  max-width: 100%;
}

.card__heading a {
  width: 70%;
}

.card__heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* single view --------------------------------------------------------------------------- */

.single__product {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.product__img {
  width: 30vw;
  height: 30vw;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.single__name {
  font-size: 2.6rem;
  margin-bottom: 0.8rem;
}

.single__product__price {
  font-size: 2.6rem;
  color: var(--secondary);
  margin-bottom: 0.7rem;
}

.single__category {
  font-size: 0.9rem;
  color: var(--primary);
}

.single__description {
  margin-bottom: 2rem;
}

.rating__stars {
  display: flex;
  justify-content: flex-end;
}

.single__ratings > .rating__bar > .rating__stars {
  display: flex;
  justify-content: flex-start;
}

/* cart --------------------------------------------------------------------- */

.cart {
  max-width: 1200px;
  margin: 0 auto;
}

.cart__item {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  margin-bottom: 1rem;
}

.cart__item__left {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.cart__item__right {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.cart__thumbnail {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.cart__box .cart__thumbnail {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.cart__item__qty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart__item__qty .quantity__icon {
  width: 60px;
}

.cart__item__price {
  width: 150px;
  text-align: right;
}

.cart__box .cart__item__price {
  width: 60px;
  text-align: right;
}

.cart__item h3 {
  margin-bottom: 0;
}

.cart__item__icon {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: var(--dark);
}

.cart__item__icon:hover {
  cursor: pointer;
  font-size: 1.3rem;
  color: red;
}

.cart__details {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.cart__details h3 {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.cart__details .cart__item__price {
  font-size: 1.7rem;
  color: var(--primary);
  border-top: 1px solid var(--primary);
}

.cart__summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
}

.cart__box h3 {
  font-size: 1rem;
  font-weight: 600;
}

.cart__box-summary {
  width: 100% !important;
}

/* quantity --------------------------------------------------------------------------------- */

.quantity {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.quantity__icon {
  color: gray;
}

.quantity__icon:hover {
  cursor: pointer;
  color: var(--primary);
}

.quantity__value {
  width: 2.5rem;
  text-align: center;
}

.quantity button {
  background-color: #fff;
}

.list__qty__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.list__qty__price .quantity {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1rem;
  margin-bottom: 0;
}

.list__qty__price .quantity__icon {
  color: gray;
}

.list__qty__price .quantity__icon:hover {
  cursor: pointer;
  color: var(--primary);
}

.list__qty__price .quantity__value {
  width: 1rem;
  text-align: center;
}

.list__qty__price .quantity button {
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
}

/* ratings ------------------------------------------------------------------------------ */

.rating__star {
  font-size: 1.5rem;
  margin-right: 10px;
  color: lightgray;
  margin-bottom: 0.4rem;
}

.rating__star__hover {
  font-size: 1.5rem;
  margin-right: 10px;
  color: gold;
  margin-bottom: 0.4rem;
}

/* footer --------------------------------------------------------------------------------*/

.footer {
  margin-top: 4rem;
  width: 100%;
  background-color: black;
  color: white;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.footer__inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1300px;
  margin: 0 auto;
}

.footer__part {
  width: 100%;
}

.footer__navigation {
  text-align: left;
  width: 55%;
}

.footer__info {
  text-align: left;
  margin-left: 40px;
}

.footer__info p {
  margin-bottom: 0.5rem;
}

.footer__seo p {
  max-width: 90%;
}

.footer__seo .logo {
  margin-top: -20px;
}

.footer .navigation__list li {
  margin: 0;
}

/* formularz ------------------------------------------------------------------- */

.login__page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form__card {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  padding: 2rem;
  background-color: #f9f9f9;
  box-shadow: 0px 5px 15px 0px #808080;
  border-radius: 10px;
}

.form__card .logo img {
  width: 100%;
}

.form__card .logo {
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form input,
.form textarea {
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  border-color: #333;
  border: 1px solid;
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-family: var(--mainFont);
}

.form button {
  width: 100%;
  margin-bottom: 15px;
}

.form__card a {
  width: 100%;
}

.form__card a button {
  width: 100%;
}

.form__card p {
  text-align: center;
}

.type_client::before {
  content: "Klient";
  color: rgb(44, 86, 51);
}

.type_partner30::before {
  content: "Partner 30";
  color: teal;
}

.type_partner20::before {
  content: "Partner 20";
  color: teal;
}

.type_gabinet::before {
  content: "Gabinet";
  color: teal;
}

.type_admin::before {
  content: "Admin";
  color: teal;
}

input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.7);
  /* IE */
  -moz-transform: scale(1.7);
  /* FF */
  -webkit-transform: scale(1.7);
  /* Safari and Chrome */
  -o-transform: scale(1.7);
  /* Opera */
  transform: scale(1.7);
  padding: 10px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext {
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}

/* user account --------------------------------------------------------------- */

.user__account {
  margin: 3rem auto 3rem auto;
  max-width: 1000px;
}

.user__account__card {
  color: var(--primary);
  padding: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e2e2e2;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
}

.user__account__card:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.3s all ease-in-out;
}

.user__account__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  font-size: 2rem;
}

.user__account__cardname {
  margin-bottom: 0;
}

/* order ------------------------------------------------------------------- */

.order__table {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.order__right,
.order__left {
  width: 100%;
  max-width: 600px;
}

.order__form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.order__form label {
  margin-top: 10px;
}

.order__form input {
  margin-bottom: 10px;
  margin-top: 5px;
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.order__form select {
  margin-bottom: 10px;
  margin-top: 5px;
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.order__form textarea {
  margin-bottom: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.input__box {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.input__line {
  display: flex;
  gap: 10px;
}

.input__line__checkbox {
  display: flex;
  align-items: flex-start;
}

.input__line__checkbox input {
  height: 100%;
  margin-top: 3px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.input__line__checkbox label {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

.detail__item {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 0.5rem 0.5rem;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.detail__item input {
  margin-right: 10px;
}

.summary,
.payment {
  margin-top: 2.5rem;
}

.order__price {
  font-weight: 800;
}

.sum {
  color: var(--primary);
  font-size: 1.4rem;
  font-weight: 900;
}

.payments__img {
  display: flex;
  justify-content: center;
}

.payments__img img {
  width: 50%;
  max-width: 220px;
}

.order__btn {
  width: 100%;
  padding: 1.7rem 1rem;
  margin-top: 1rem;
  border: 0px;
  border-radius: 10px;
  color: white;
  font-size: 2rem;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
}

.title {
  text-align: center;
}

.undertitle {
  max-width: 1100px;
  margin: 0 auto;
}

.input__err {
  color: red;
  font-size: 0.8rem;
  margin-top: -5px;
  display: block;
}

input:invalid[focused="true"] ~ .input__err {
  display: block;
}

select:invalid[focused="true"] ~ .input__err {
  display: block;
}

.form__input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.orderPage {
  margin-top: 0.4rem;
}

.orderPage > form > .login__box {
  display: flex;
  gap: 20px;
}

.orderPage > form > .login__box input {
  margin-bottom: 10px;
  margin-top: 5px;
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.orderPage > form > button {
  height: 2.5rem;
  padding: 0;
  color: white;
}

.inpost__details {
  padding-left: 1rem;
}

/* my account page ---------------------------------------------------------------- */

.data__form {
  display: flex;
  flex-direction: row;
  gap: 40px;
  max-width: 100%;
}

.table__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.table__col1 {
  width: 30%;
  text-align: left;
}

.table__col2 {
  width: 30%;
  text-align: left;
}

.table__col3 {
  width: 20%;
  text-align: right;
}

.table__col4 {
  width: 20%;
  text-align: right;
}

.table__price {
  font-weight: 800;
  color: var(--primary);
}

.myaccount__orders {
  margin-bottom: 3rem;
}

.myAccount__user {
  margin-bottom: 3rem;
}

.account__email {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
}

.account__email__left {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.account__email .form__input {
  width: 100%;
}

.account__email .form__input input {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 5px;
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.account__email button {
  min-width: 200px;
  height: 3.5rem;
}

.p {
  margin-bottom: 1rem;
}

.errors {
  margin-top: 1rem;
}

/* home page ------------------------------------------------------------ */

.page {
  background-image: url("../public/assets/bg.png");
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
  max-width: 1300px;
  margin: 0 auto;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
  min-height: 500px;
  background-image: url("../public/assets/gradient.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.hero__box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  max-width: 1200px;
}

.hero__text {
  flex-basis: 50%;
  color: white;
  font-size: 1.5rem;
  font-family: var(--mainFont);
}

.hero__img {
  flex-basis: 40%;
  padding: 3rem;
}

.hero__text img {
  margin-left: -14px;
}

.sectionLeft,
.sectionRight,
.sectionCenter {
  margin-top: 3rem;
}

.sectionLeft__inner,
.sectionRight__inner,
.sectionCenter__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.sectionCenter__inner {
  flex-direction: column;
}

.sectionCenter__inner h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.sectionCenter__inner p {
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

.sectionLeft__text,
.sectionRight__text,
.sectionCenter__inner {
  flex-basis: 50%;
}

.sectionLeft__text h2,
.sectionRight__text h2,
.sectionCenter__inner h2 {
  color: var(--primary);
  font-size: 3rem;
}

.sectionIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.sectionIcons__icon {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--accent);
}

.sectionIcons__icon p {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.sectionIcons__img {
  display: block;
  width: 150px;
  height: 150px;
  padding: 15px;
}

.sectionIcons__img img {
  width: 100%;
  max-height: 90%;
}

.sectionHeader__background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 300px;
}

.sectionHeader__title {
  font-size: 5rem;
  max-width: 1300px;
  width: 100%;
  text-align: left;
  color: white;
  margin-bottom: 0;
}

#geowidget-v5-iframe-render {
  height: 650px !important;
}

.modal {
  display: block;
  position: fixed;
  right: 0;
  top: 6rem;
  background-color: var(--primary);
  width: 60vw;
  min-height: 90vh;
  transition: all 0.3s ease;
}

.mobile__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 90vh;
  padding-right: 4rem;
}

.mobile__menu .navigation {
  color: var(--light);
  font-size: 1.4rem;
  text-align: right;
}

.mobile__menu .navigation li {
  margin-bottom: 2rem;
  margin-right: 0px;
}

.mobile__menu .icons a {
  height: 35px;
  width: 35px;
  font-size: 35px;
}

.hidden {
  right: -70%;
  transition: all 0.3s ease;
}

.adminArea__none {
  display: none;
}

.mobile {
  display: none;
}

.success {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}

/* skeletons ----------------------------------------------------------------- */

.skeleton__wraper {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.skeleton__element {
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
  position: relative;
}

.skeleton__overlay {
  width: 30%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(-20deg);
  background-color: #ffffff66;
  animation: loadingAnimation 2s ease infinite;
  box-shadow: 0 0 20px 20px #ffffff66;
}

.skeleton__wraper.thumb {
  width: 300px;
  height: 300px;
}

.skeleton__wraper.title {
  width: 300px;
  height: 30px;
  margin-top: 0;
}

.skeleton__wraper.btn {
  width: 300px;
  height: 55px;
}

.skeleton__wraper.thumb-item {
  width: 200px;
  height: 200px;
}

.skeleton__wraper.title-item {
  width: 50%;
  height: 30px;
}

.skeleton__wraper.line-item {
  width: 100%;
  height: 15px;
}

.skeleton__wraper.btn-item {
  width: 200px;
  height: 55px;
}

@keyframes loadingAnimation {
  0% {
    left: -70%;
  }

  50% {
    left: -50%;
  }

  100% {
    left: 125%;
  }
}

.skeleton__card {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 45px;
  margin-top: 4rem;
}

.skeleton__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  max-width: 800px;
  margin-top: 5rem;
}

.skeleton__listitem {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}

.skeleton__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  height: 200px;
}

.skeleton__buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart__discount {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cart__discount input {
  width: 230px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cart__discount h4 {
  width: 200px;
}

.discount-btn {
  padding: 0.5rem 1rem;
}

/* help page ------------------------------------------------ */

.help-form {
  max-width: 500px;
  margin: 0 auto;
}

.help-form input {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 1.2rem;
  font-family: var(--mainFont);
}

.help-form textarea {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 1.2rem;
  font-family: var(--mainFont);
}

.help-form label,
.help-form p {
  margin-top: 1rem;
}

.help-form select {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 1.2rem;
  font-family: var(--mainFont);
}

.help-form .input__err {
  margin-top: 0px !important;
}

/* tax validation fields ------------------------------------------------------ */

.tax-number-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.tax-number-input .button-validation {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  width: 50px;
  height: 50px;
  margin-bottom: 0;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.tax-number-input .btn-active {
  background-color: var(--primary);
  color: var(--light);
}

.tax-number-input .btn-disabled {
  background-color: rgb(125, 224, 120);
  color: var(--light);
}

.tax-number-input .btn-active:hover {
  background-color: var(--dark);
  transition: all 0.3s ease;
}

select {
  padding: 0.7rem 0.7rem;
  margin-bottom: 20px;
}

.field-description {
  text-align: left !important;
  font-size: 0.7rem;
  margin-top: 10px;
  margin-bottom: 30px;
}

.btn-disable {
  background-color: gray;
  color: rgb(81, 81, 81);
}

.btn-disable:hover {
  background-color: gray;
  color: rgb(81, 81, 81);
}

.idle-tax {
  color: green;
}

/* responsive ---------------------------------------------------------------- */

@media screen and (max-width: 1000px) {
  .list__item {
    flex-direction: column;
  }

  .mobile {
    display: block;
  }

  .cart__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
  }

  .grid {
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .single__product {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .product__img {
    width: 60vw;
    height: 60vw;
    margin-top: 2rem;
  }

  .list__card {
    margin-bottom: 5.5rem;
  }

  .list__item__info {
    max-width: 700px;
    width: 90%;
  }

  .list__item .product__btn {
    width: 90%;
  }

  .desktop {
    display: none;
  }

  .header_out {
    justify-content: space-around;
  }

  body,
  html {
    overflow-x: hidden;
    width: 100vw;
  }

  .order__table {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .order .undertitle {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cart__item {
    margin-bottom: 4rem;
  }

  .cart__summary {
    align-items: center;
  }

  .cart__details {
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  .cart__details .cart__item__price {
    text-align: center;
  }

  .footer__inner {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
  }

  .footer__navigation {
    text-align: center;
    width: 100%;
    margin-bottom: 3rem;
  }

  .footer__info {
    text-align: center;
    margin-left: 0px;
  }

  .footer__seo p {
    margin: 0 0 30px 0;
    max-width: 100%;
  }

  .user__account {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .account__email {
    flex-direction: column;
  }

  .data__form {
    flex-direction: column;
  }

  .quantity {
    justify-content: center;
  }
}

@media screen and (max-width: 1400px) {
  .adminArea__none {
    display: block;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    color: orange;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .adminArea__main {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .grid {
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.warningPop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningPop__content {
  background-color: white;
  max-width: 650px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.discount-price-new {
  display: flex;
  gap: 5px;
  color: red;
  margin-right: 15px;
}

.discount-price-old {
  display: flex;
  gap: 5px;
  color: gray;
  text-decoration: line-through;
}

.single__product__price_new {
  display: flex;
  gap: 5px;
  color: red;
  margin-right: 15px;
}

.single__product__price_old {
  display: flex;
  gap: 5px;
  color: gray;
  text-decoration: line-through;
}

.promoBaner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  margin-top: 2rem;
  color: var(--light);
  background: linear-gradient(90deg, #1cb5e0bd 0%, #000851a6 80%), url("../public/assets/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.promoBaner-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  margin-top: 2rem;
  color: var(--light);
  background: linear-gradient(90deg, #f77d2cbd 0%, #610600a6 80%), url("../public/assets/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.promoBaner h2 {
  font-size: 2.5rem;
}

.promoBaner p {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.promoBaner-alt h2 {
  font-size: 2.5rem;
}

.promoBaner-alt p {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.table-orders-mobile-description {
  display: none;
}

@media screen and (max-width: 750px) {
  .table-orders-mobile {
    display: flex;
    flex-direction: column;
  }
  .table-orders-mobile-line::after {
    display: inline-block;
  }

  .table-orders-mobile-labels {
    display: none;
  }
  .table-orders-mobile-description {
    display: inline;
  }
  table-orders-mobile-row {
    width: 100%;
    justify-content: space-between;
  }
  table-orders-mobile-row > div {
    width: 100%;
  }
  .table-orders-mobile-row > .table__col1 {
    width: 100%;
    text-align: right;
    font-weight: 900;
  }
  .table-orders-mobile-row > .table__col2 {
    width: 100%;
    text-align: right;
  }
  .table-orders-mobile-row > .table__col3 {
    width: 100%;
    text-align: right;
  }
  .table-orders-mobile-row > .table__col4 {
    width: 100%;
    text-align: right;
  }
}
