/* admin area --------------------------------------------------------------- */

.adminArea {
  max-width: 1400px;
  margin: 3rem auto 3rem auto;
}

.adminArea__main {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
}

.adminMenu {
  flex-basis: 20%;
  min-height: 500px;
  max-height: 500px;
  padding: 4rem 0 2rem 3rem;
  background-color: var(--secondary);
  color: var(--light);
  font-size: 1.4rem;
  border-radius: 20px;
}

.adminMenu__list {
  display: block;
  min-width: 100%;
}

.adminMenu__item {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 1.1rem;
  margin-right: 0;
  width: 100%;
  font-weight: 700;
  transition: all 0.3s ease;
  border-radius: 20px 0 0 20px;
}

.adminMenu__item:hover {
  cursor: pointer;
  background-color: white;
  color: var(--primary);
}

.adminMenu__item .active {
  background-color: white;
  color: var(--primary);
}

.adminArea__module {
  flex-basis: 100%;
  padding-left: 3rem;
  min-height: 60vh;
}

.adminOrders__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1.2rem;
}

.adminOrders__col.unread {
  color: red;
}

.adminOrders__col.id {
  width: 5%;
  min-width: 5%;
  font-weight: 800;
}

.adminOrders__col.email {
  width: 20%;
  min-width: 20%;
  font-weight: 600;
  color: var(--primary);
}

.adminOrders__col.client {
  width: 15%;
  min-width: 15%;
}

.adminOrders__col.date {
  width: 10%;
  min-width: 10%;
}

.adminOrders__col.value {
  width: 8%;
  min-width: 8%;
  text-align: right;
  font-weight: 900;
  color: var(--primary);
}

.adminOrders__col.status {
  width: 13%;
  min-width: 13%;
  font-weight: 800;
  text-align: right;
}

.adminOrders__col.heading {
  font-weight: 800;
}

.adminOrders__col.placed::after {
  content: "Złożone";
  color: orange;
}

.adminOrders__col.COMPLETED::after {
  content: "Opłacone";
  color: rgb(113, 189, 0);
}

.adminOrders__col.FINISHED::after {
  content: "Zakończone";
  color: rgb(113, 189, 0);
}

.adminOrders__col.PENDING::after {
  content: "Oczekujące";
  color: rgb(255, 187, 0);
}

.adminOrders__col.PREP::after {
  content: "Przygotowane";
  color: #003ab7;
}

.adminOrders__col.CANCELED::after {
  content: "Anulowane";
  color: hsl(0, 100%, 36%);
}

.adminOrders__searchbar {
  justify-content: flex-start;
}

.adminOrders__searchbar .fieldBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.adminOrders__searchbar .fieldBox input,
.adminOrders__searchbar .fieldBox select {
  height: 2.2rem;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid gray;
}

.order_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  max-width: 70%;
}

.order_control {
  flex-basis: 100%;
  width: 100%;
}

.clientData__value {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.clientData__h {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.clientData__heading {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
}

.clientData {
  display: flex;
  gap: 3rem;
}

.clientData__data {
  min-width: 40%;
}

.adminOrdersItem_row {
  display: flex;
  justify-content: space-between;
  max-width: 70%;
}

.adminOrdersItem_row .a-name {
  width: 50%;
  font-weight: 700;
}

.adminOrdersItem_row .a-quantity {
  width: 20%;
  text-align: right;
}

.adminOrdersItem_row .a-value {
  width: 30%;
  text-align: right;
  font-weight: 900;
  color: var(--primary);
}

.adminOrdersItem_row__total {
  max-width: 70%;
  display: flex;
  margin-top: 2rem;
  border-top: 1px solid var(--primary);
  gap: 2rem;
  justify-content: flex-end;
  font-size: 1.3rem;
  font-weight: 800;
  color: var(--primary);
}

.adminOrdersSingle > div > .fieldBox {
  display: flex;
  gap: 3rem;
}

.statusChange {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 300px;
}

.statusChange select {
  height: 2rem;
  width: 100%;
}

.statusChange button {
  height: 2rem;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  font-weight: 500;
}

.statusChange button:hover {
  background-color: #333;
  color: #f9f9f9;
}

.note__entry {
  margin-bottom: 0.5rem;
}

.note__date {
  font-weight: 600;
}

.placed {
  font-weight: 700;
  color: orange;
}

.COMPLETED {
  font-weight: 700;
  color: rgb(113, 189, 0);
}

.FINISHED {
  font-weight: 700;
  color: rgb(113, 189, 0);
}

.PENDING {
  font-weight: 700;
  color: rgb(255, 187, 0);
}

.CANCELED {
  font-weight: 700;
  color: red;
}

.PREP {
  font-weight: 700;
  color: #0027d4;
}

.prodName {
  width: 30%;
  color: var(--secondary);
  text-align: left;
  font-weight: 800;
}

.prodNetto {
  width: 10%;
  color: var(--primary);
  text-align: right;
  font-weight: 700;
}

.prodVat {
  width: 5%;
  text-align: right;
}

.prodQty {
  width: 5%;
  color: orange;
  text-align: right;
  font-weight: 800;
}

.productForm .form__input input {
  margin-bottom: 2rem;
}

.editProduct__price-row {
  display: flex;
  gap: 1rem;
}

.editProduct__product .form {
  flex-basis: 60%;
}

.editProduct__product .form .form__input {
  width: 100%;
}

.editProduct__product .img {
  width: 50%;
}

.productForm {
  display: flex;
  gap: 2rem;
}

.productForm .left,
.right {
  width: 100%;
}

.editProduct__controlls {
  display: flex;
  gap: 4rem;
}

.editProduct__controlls a,
.editProduct__controlls button {
  width: 100%;
  flex-basis: 100%;
}

.userDate {
  width: 15%;
  color: var(--primary);
  text-align: right;
}

.userRole {
  width: 15%;
  text-align: right;
  font-weight: 700;
}

#user_form {
  margin: 0 0;
}

.adminDashboard__filter {
  max-width: 50%;
}

.adminDashboard__filter .adminOrders__searchbar {
  justify-content: flex-start;
}

.adminDashboard__value {
  margin-top: 4rem;
}

.adminDashboard__value h3,
.adminDashboard__value h4 {
  margin-bottom: 1rem;
}

.adminDashboard__value h1 {
  color: var(--primary);
  margin-bottom: 1rem;
}

.adminDashboard__value h2 {
  color: var(--secondary);
}

.adminDashboard__valueInner {
  flex-basis: 100%;
}

.adminDashboard__orders {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.adminDashboard__newOrders {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.adminDashboard__order {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.adminDashboard__statusInner {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  max-width: 500px;
}

.adminDashboard__statusBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adminDashboard__statusBox .Złożone {
  font-weight: 700;
  color: orange;
}

.adminDashboard__statusBox .Opłacone {
  font-weight: 700;
  color: rgb(91, 151, 0);
}

.adminDashboard__statusBox .Zakończone {
  font-weight: 700;
  color: rgb(11, 72, 163);
}

.adminDashboard__statusBox .Oczekujące {
  font-weight: 700;
  color: rgb(124, 0, 128);
}

.adminDashboard__statusBox .Anulowane {
  font-weight: 700;
  color: red;
}

.adminDashboard__status {
  flex-basis: 100%;
}
