/* layout ---------------------------------------- */

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.j-center {
  justify-content: center;
}

.j-start {
  justify-content: flex-start;
}

.j-end {
  justify-content: flex-end;
}

.a-center {
  align-items: center;
}

.a-start {
  align-items: flex-start;
}

.a-end {
  align-items: flex-end;
}

.g1 {
  gap: 1rem;
}

.g2 {
  gap: 2rem;
}

.g3 {
  gap: 3rem;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.w75 {
  width: 75%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w40 {
  width: 40%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

/* margin & padding ------------------------------------------- */

.m-auto {
  display: block;
  margin: 0 auto;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.ml4 {
  margin-left: 4rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mr4 {
  margin-right: 4rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.pl4 {
  padding-left: 4rem;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pr4 {
  padding-right: 4rem;
}

/* typography --------------------------------------- */

.s1 {
  font-size: 1rem;
}

.s1_5 {
  font-size: 1.5rem;
}

.s2 {
  font-size: 2rem;
}

.s2_5 {
  font-size: 2.5rem;
}

.s3 {
  font-size: 3rem;
}

.s3_5 {
  font-size: 3.5rem;
}

.s4 {
  font-size: 4rem;
}

.s5 {
  font-size: 5rem;
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.t-center {
  text-align: center;
}

/* styling ---------------------------------------------------- */

.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-blur {
  backdrop-filter: blur(5px);
}

/* responsive -------------------------- */

@media screen and (max-width: 1200px) {
  .m-column {
    flex-direction: column;
  }

  .w100,
  .w20,
  .w25,
  .w40,
  .w50,
  .w60,
  .w75,
  .w80 {
    width: 100%;
  }
}
@media screen and (min-width: 751px) {
  .hide-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 750px) {
  .hide-mobile {
    display: none !important;
  }
}
